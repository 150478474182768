import React, {useState, useEffect} from 'react';
import TokenProgressBar from '../../../components/TokenProgressBar/TokenProgressBar';
import {getLocalizedMessage} from '../../../utilityFunction/helper';
import {useIntl} from 'react-intl';
import './StudentWelcomeBody.scss';
import {useNavigate} from '../../../routes';
import {
  MANAGE_AVATAR_PATH,
  MANAGE_ISLAND_LIST,
} from '../../../constants/routePaths';
import ThreeDButton from '../../../components/ThreeDButton/ThreeDButton';
import {getLocalStorageItem} from '../../../services/local-storage';

function StudentWelcomeBody() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showStartButton, setShowStartButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStartButton(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleStartLesson = () => {
    const selectedAvatarId = getLocalStorageItem('studentIslandAvatar');
    if (selectedAvatarId) {
      navigate(MANAGE_ISLAND_LIST);
    } else {
      navigate(MANAGE_AVATAR_PATH);
    }
  };

  return (
    <div className="student-wrapper">
      <div className="text-center student-welcome-body ">
        <div className="logo">
          <img src="/images/logo.svg" alt="Gradrock Logo" />
        </div>
        <div className="welcome-text montserrat-bold">
          <div>{getLocalizedMessage(intl, 'label.student_welcome_text')}</div>
          <div>{getLocalizedMessage(intl, 'label.student_welcome_text2')}</div>
        </div>
        {!showStartButton && (
          <div className="starting_progress">
            <div className="welcome-text montserrat-bold">
              {getLocalizedMessage(intl, 'label.starting_up')}
            </div>
            <TokenProgressBar total={0} dummyProgress />
          </div>
        )}
        {showStartButton && (
          <ThreeDButton
            labelClassName="montserrat-bold text-uppercase"
            handleClick={handleStartLesson}
            labelText={intl.formatMessage({id: 'label.start_lesson'})}
          />
        )}
      </div>
    </div>
  );
}

export default StudentWelcomeBody;
