import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const handleUploadFile = createAsyncThunk(
  'upload/file',
  async ({file, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axiosPrivateInstance.post('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const downloadFileAttachments = createAsyncThunk(
  'download/attachmentFile',
  async ({idMedia, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(
        `/download-media?id=${idMedia}`,
        {responseType: 'blob'},
      );

      const blob = new Blob([response.data]);

      // Create an object URL for the Blob
      const downloadUrl = window.URL.createObjectURL(blob);
      const fileName = `attachment-${idMedia}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
      link.remove();
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.download.fileAttachment'),
        'success',
      );
      return;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

// @TODO - Alternative approach to download file
export const downloadAttachments = createAsyncThunk(
  'download/attachmentFile',
  async ({media, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      // @TODO - Change the database value for Entreprenuership attachment. Change it from docx to pdf since the available file is in pdf format. Remove the hard coded path after.
      const path =
        media.name === '1715819698_Entrepreneurship.docx'
          ? 'https://gradrockapp.ydodev.com/backend/storages/media/T4-F Entrepreneurship.pdf'
          : media.path;
      const downloadUrl = path;
      const fileName = `attachment-${
        media.name === '1715819698_Entrepreneurship.docx'
          ? 'T4-F Entrepreneurship.pdf'
          : media.name
      }`;

      const response = await fetch(media.path);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Creating a temporary anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      // link.target = '_blank';
      link.setAttribute('download', media.path.split('/').pop()); // Extracting the filename from the path
      // link.setAttribute('download', fileName);

      // Appending the anchor to the body, triggering the click, and removing it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Releasing the URL object
      window.URL.revokeObjectURL(downloadUrl);

      // Disabling the loader and showing a success message
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.download.fileAttachment'),
        'success',
      );
    } catch (error) {
      // Handling errors and disabling the loader
      loaderDispatch(setLoading(false));
      console.log(error, 'error fileActions');
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response?.data?.message || 'An error occurred';
    }
  },
);

export const handleStoreAttachment = createAsyncThunk(
  'attachment/store',
  async ({lesson_id, attachment, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const formData = new FormData();

      formData.append('lesson_id', lesson_id);
      formData.append('file', attachment);

      const response = await axiosPrivateInstance.post(
        '/attachment/store',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showToast(
        getLocalizedMessage(intl, 'success.download.fileAttachment'),
        'success',
      );

      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));

      const errorMessage =
        error.response?.data?.message ||
        getLocalizedMessage(intl, 'error.somethingWentWrong');

      showToast(errorMessage, 'error');
      throw thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

export const getAttachmentById = createAsyncThunk(
  'attachment/getById',
  async ({lessonId, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(
        `/attachments/${lessonId}`,
      );
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getAllAttachments = createAsyncThunk(
  'attachments/getAll',
  async ({intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(`/student-attachments`);
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
