import React from 'react';
import {useIntl} from 'react-intl';
import {getLocalizedMessage} from '../../../../../utilityFunction/helper';
import {commonStyle} from '../../../../../Style/commonStyle';
import './AddContentButtons.scss';
import {LESSON_CONTENT_OBJECT_TYPE} from '../../../../../utilityFunction/constants';

export default function AddContentButtons({
  handleAddContentType,
  handleAddTextType,
  saveDraftHandler,
  seePreviewHandler,
  publishContentHandler,
  generateTextToSpeechHandler,
}) {
  const intl = useIntl();
  const CONTENT_IMAGES = [
    [
      {
        icon: '/images/text.png',
        text: getLocalizedMessage(intl, 'label.content.text'),
        type: LESSON_CONTENT_OBJECT_TYPE.TEXT,
      },
      {
        icon: '/images/attachment.png',
        text: getLocalizedMessage(intl, 'label.content.attachment'),
        type: LESSON_CONTENT_OBJECT_TYPE.DOCUMENT,
      },
    ],
    [
      {
        icon: '/images/videoPlay.svg',
        text: getLocalizedMessage(intl, 'label.content.image'),
        type: LESSON_CONTENT_OBJECT_TYPE.IMAGE_VIDEO,
      },
      {
        icon: '/images/export.svg',
        text: getLocalizedMessage(intl, 'label.content.articles'),
        type: LESSON_CONTENT_OBJECT_TYPE.ARTICLE,
      },
    ],
  ];
  const TEXT_TYPE_IMAGES = [
    [
      {
        icon: '/images/alarm.svg',
        text: getLocalizedMessage(intl, 'label.content.alert'),
        type: LESSON_CONTENT_OBJECT_TYPE.ALERT,
      },
      {
        icon: '/images/note.svg',
        text: getLocalizedMessage(intl, 'label.content.note'),
        type: LESSON_CONTENT_OBJECT_TYPE.NOTE,
      },
    ],
    [
      {
        icon: '/images/remember.png',
        text: getLocalizedMessage(intl, 'label.content.remember'),
        type: LESSON_CONTENT_OBJECT_TYPE.REMEMBER,
      },
      {
        icon: '/images/liketag.png',
        text: getLocalizedMessage(intl, 'label.content.bestAdvice'),
        type: LESSON_CONTENT_OBJECT_TYPE.BEST_ADVICE,
      },
    ],
  ];

  return (
    <div className="add-content-button-wrapper">
      <div className="text-and-images-container">
        <div className="text-label">
          {getLocalizedMessage(intl, 'label.text.addContent')}
        </div>

        <div className="images-container">
          {CONTENT_IMAGES.map((row, rowIndex) => (
            <div key={`row-${rowIndex}`} className="image-row">
              {row.map((item, itemIndex) => (
                <div
                  key={`item-${rowIndex}-${itemIndex}`}
                  className="content-label-wrapper"
                  onClick={() => handleAddContentType(item.type)}>
                  <img className="add-content-icon" src={item.icon} />
                  <div className="item-text">{item.text}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="text-and-images-container">
        <div className="text-label">
          {getLocalizedMessage(intl, 'label.text.addText')}
        </div>

        <div className="images-container">
          {TEXT_TYPE_IMAGES.map((row, rowIndex) => (
            <div key={`row-${rowIndex}`} className="image-row">
              {row.map((item, itemIndex) => (
                <div
                  key={`item-${rowIndex}-${itemIndex}`}
                  className="content-label-wrapper"
                  onClick={() => handleAddTextType(item.type)}>
                  <img className="add-content-icon" src={item.icon} />
                  <div className="item-text">{item.text}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="add-content-actions-container">
        <button style={commonStyle.confirmBtn} onClick={publishContentHandler}>
          {getLocalizedMessage(intl, 'label.publishContent')}
        </button>
        <button
          style={commonStyle.cancelBtn}
          onClick={saveDraftHandler}
          type="submit">
          {getLocalizedMessage(intl, 'label.saveAsDraft')}
        </button>
        <button style={commonStyle.cancelBtn} onClick={seePreviewHandler}>
          {getLocalizedMessage(intl, 'label.viewPreview')}
        </button>
        <button
          style={commonStyle.cancelBtn}
          className="btn btn-outline-secondary"
          onClick={generateTextToSpeechHandler}>
          {getLocalizedMessage(intl, 'label.generateTextToSpeech')}
        </button>
      </div>
    </div>
  );
}
