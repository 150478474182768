import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../../components/InputLabel/InputLabel';
import {getLocalizedMessage} from '../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {
  lessonDetailsFormFieldNames,
  lessonDetailsFormInitialValues,
  validationSchema,
} from '../../../validationSchema/editLessonSchema';
import {
  editLessonDetails,
  getSectionsLesson,
} from '../../../store/actions/lessonActions';
import {showToast} from '../../../components/Toast/Toast';
import {ADD_LESSON_CONTENT_PATH} from '../../../constants/routePaths';

export default function LessonDetailsForm({
  open,
  setOpen,
  lessonData,
  setSelectedLesson,
  sectionId,
  isEditView,
  setIsEditView,
  sectionName,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [initialFormData, setInitialFormData] = useState([]);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: lessonDetailsFormInitialValues,
    onSubmit: async values => {
      if (isEditView && lessonData) {
        if (!checkFormHasChanged()) {
          showToast(
            getLocalizedMessage(intl, 'label.noModuleDetailsChanged'),
            'warning',
          );
          return;
        }

        const payload = {
          ...values,
          section_id: sectionId,
        };
        setOpen(false);
        await dispatch(
          editLessonDetails({
            payload,
            lessonId: lessonData?.id,
            intl,
          }),
        );
        setIsEditView(false);
        setSelectedLesson(null);
        await dispatch(getSectionsLesson({loaderDispatch, sectionId}));
      } else {
        if (!isEditView) {
          navigate(ADD_LESSON_CONTENT_PATH, {
            state: {
              lessonData: values,
              sectionId: sectionId,
              isEditContent: false,
              sectionName: sectionName,
            },
          });
        }
      }
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    if (isEditView && lessonData) {
      setValues(prev => ({
        ...prev,
        [lessonDetailsFormFieldNames.LESSON_NAME]: lessonData?.name,
        [lessonDetailsFormFieldNames.LESSON_DESC]:
          lessonData?.short_description,
        [lessonDetailsFormFieldNames.MIN_READ_TIME]:
          lessonData?.minimum_read_time,
      }));
      setInitialFormData({
        [lessonDetailsFormFieldNames.LESSON_NAME]: lessonData?.name,
        [lessonDetailsFormFieldNames.LESSON_DESC]:
          lessonData?.short_description,
        [lessonDetailsFormFieldNames.MIN_READ_TIME]:
          lessonData?.minimum_read_time,
      });
    }
  }, [isEditView, lessonData]);

  const closeLessonModal = () => {
    setIsEditView(false);
    setOpen(false);
    setSelectedLesson(null);
  };

  const checkFormHasChanged = () => {
    const formChanged =
      JSON.stringify(initialFormData) !== JSON.stringify(values);
    return formChanged;
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeLessonModal}
      cancelText={getLocalizedMessage(intl, 'label.discard')}
      confirmText={
        isEditView
          ? getLocalizedMessage(intl, 'label.saveChanges')
          : getLocalizedMessage(intl, 'label.addContent')
      }
      dialogueTitle={getLocalizedMessage(intl, 'label.title.lessonDetails')}
      onSubmit={handleSubmit}
      className={'center-btn'}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <InputLabel
              name={lessonDetailsFormFieldNames.LESSON_NAME}
              label={getLocalizedMessage(intl, 'lessonDetails.lessonName')}
              placeholder={getLocalizedMessage(
                intl,
                'lessonDetails.lessonName.placeholder',
              )}
              required
              value={values[lessonDetailsFormFieldNames.LESSON_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[lessonDetailsFormFieldNames.LESSON_NAME] &&
                errors[lessonDetailsFormFieldNames.LESSON_NAME]
              }
            />
          </div>

          <div className="mb-3 col-md-12">
            <InputLabel
              name={lessonDetailsFormFieldNames.LESSON_DESC}
              label={getLocalizedMessage(intl, 'lessonDetails.lessonShortDesc')}
              placeholder={getLocalizedMessage(
                intl,
                'lessonDetails.lessonShortDesc.placeholder',
              )}
              value={values[lessonDetailsFormFieldNames.LESSON_DESC]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[lessonDetailsFormFieldNames.LESSON_DESC] &&
                errors[lessonDetailsFormFieldNames.LESSON_DESC]
              }
            />
          </div>

          <div className="mb-3 col-md-12">
            <InputLabel
              name={lessonDetailsFormFieldNames.MIN_READ_TIME}
              label={getLocalizedMessage(intl, 'lessonDetails.lessonMinutes')}
              placeholder={getLocalizedMessage(
                intl,
                'lessonDetails.lessonSeconds.placeholder',
              )}
              required
              value={values[lessonDetailsFormFieldNames.MIN_READ_TIME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[lessonDetailsFormFieldNames.MIN_READ_TIME] &&
                errors[lessonDetailsFormFieldNames.MIN_READ_TIME]
              }
              showEndAdornment
              endAdornment={
                <div>
                  {getLocalizedMessage(intl, 'label.placeholder.seconds')}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
}
