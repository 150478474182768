import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const downloadPilotReport = createAsyncThunk(
  'reports/downloadPilotReport',
  async ({intl, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.get(`/school-pilot-report`);
      loaderDispatch(setLoading(false));
      return response.data.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), error);
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);
