import React from 'react';

import DashboardView from '../views/Dashboard';
import ProfileView from '../views/Profile';
import LoginForm from '../views/LoginForm';
import Home from '../pages/Home';
import Auth from '../pages/Auth';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import ManageSchoolListing from '../views/ManageSchool';
import AddSchool from '../views/AddSchool';
import ManageSchoolDistrictsListing from '../views/ManageSchoolDistricts/ManageSchoolDistrictsListing';
import StudentListing from '../views/AddStudent/StudentListing';
import AddStudentForm from '../views/AddStudent/AddStudentForm';
import ManageStaffListing from '../views/ManageStaff/ManageStaffListing';
import AddStaffForm from '../views/ManageStaff/AddStaffForm';
import TemporaryLogin from '../views/TemporaryLogin/TemporaryLogin';
import IslandsListing from '../views/ManageContents/IslandDetails/IslandsListing';
import SectionListing from '../views/ManageContents/SectionDetails/SectionListing';
import AvatarList from '../views/Student/AvatarList/AvatarList';
import ManageLessonListing from '../views/ManageContents/LessonDetails/ManageLessonsListing/ManageLessonListing';
import EditLessonStyle from '../views/ManageContents/IslandDetails/EditLessonStyle';
import AddLessonContent from '../views/ManageContents/LessonDetails/AddNewLesson/AddLessonContent';
import StudentViewLessonContent from '../views/Student/StudentViewLessonContent/StudentViewLessonContent';
import ManageQuestionListView from '../views/ManageQuestionListView/ManageQuestionListView';
import UserProfile from '../views/UserProfile/UserProfile';
import StudentDetailsDashboardView from '../views/SchoolAdminAndStaffDashboardView/StudentDetailsDashboardView/StudentDetailsDashboardView';
import AllNotifcationsAndAnnouncementsListView from '../views/AllNotificationsAndAnnouncementsListView/AllNotifcationsAndAnnouncementsListView';
import StudentProgressReportListing from '../views/Reports/StudentProgressReportListing';
import PilotReportingView from '../views/Reports/PilotReportingView';
import StaffStudentAttachments from '../views/StudentAttachments/StaffStudentAttachments';

// For Student
import StudentIslandList from '../views/Student/StudentIslandList/StudentIslandList';
import StudentWelcomeBody from '../views/Student/StudentWelcomeBody/StudentWelcomeBody';
import TropicalSection from '../views/Student/StudentSections/Tropical/TropicalSection';
import StudentLesson from '../views/Student/StudentLesson/StudentLesson';
import UrbanSection from '../views/Student/StudentSections/Urban/UrbanSection';

import withPrivateAccess from '../hocs/withPrivateAccess';
import withPublicAccess from '../hocs/withPublicAccess';
import RuralSection from '../views/Student/StudentSections/Rural/RuralSection';
import FantasySection from '../views/Student/StudentSections/Fantasy/FantasySection';
import VerifyEmail from '../views/VerifyEmail/VerifyEmail';
import Help from '../views/Help/Help';
import Announcements from '../views/Announcements/Announcements';
import SuperAdminListing from '../views/SuperAdmin/SuperAdminListing';

import {
  ROOT_PATH,
  MANAGE_SCHOOLS_PATH,
  SCHOOL_ADMINS_PATH,
  SCHOOL_STAFF_PATH,
  STUDENTS_PATH,
  NOTIFICATIONS_PATH,
  ISLANDS_CONTENT_PATH,
  BACKUP_UPDATES_PATH,
  SYSTEM_SETTING_PATH,
  LOGIN_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  PROFILE_PATH,
  ADD_SCHOOL_PATH,
  UPDATE_SCHOOL_PATH,
  MANAGE_SCHOOL_DISTRICTS_PATH,
  MANAGE_STUDENTS_PATH,
  ADD_STUDENT_PATH,
  UPDATE_STUDENT_PATH,
  MANAGE_STAFF_PATH,
  REPORTS_PATH,
  PILOT_REPORTING_PATH,
  ADD_STAFF_PATH,
  UPDATE_STAFF_PATH,
  TEMPORARY_LOGIN_PATH,
  MANAGE_CONTENTS_PATH,
  MANAGE_SECTION_PATH,
  MANAGE_AVATAR_PATH,
  STUDENT_WELCOME_PATH,
  MANAGE_ISLAND_LIST,
  MANAGE_STUDENT_TROPICAL_SECTION__PATH,
  MANAGE_STUDENT_LESSON__PATH,
  MANAGE_LESSONS_PATH,
  LESSON_STYLE_PATH,
  ADD_LESSON_CONTENT_PATH,
  MANAGE_STUDENT_URBAN_SECTION__PATH,
  MANAGE_STUDENT_RURAL_SECTION__PATH,
  MANAGE_STUDENT_FANTASY_SECTION__PATH,
  VERIFY_EMAIL,
  SHOW_HELP,
  VIEW_PROFILE,
  VIEW_LESSON_CONTENTS,
  MANAGE_QUESTIONS_PATH,
  STUDENT_DETAILS_PATH,
  MANAGE_ANNOUNCEMENTS_PATH,
  MANAGE_SUPER_ADMIN,
  ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH,
  STAFF_STUDENT_ATTACHMENTS_PATH,
} from '../constants/routePaths';
import {
  DASHBOARD,
  ISLANDS_CONTENT,
  MANAGE_QUESTIONS,
  MANAGE_SCHOOLS,
  MANAGE_STAFF,
  MANAGE_STUDENTS,
  MY_PROFILE,
  SYSTEM_SETTINGS,
  ANNOUNCEMENTS,
  SUPER_ADMIN_MGMT,
  NOTIFY_ANNOUNCEMENTS,
  REPORTS,
  PILOT_REPORTING,
  STAFF_STUDENT_ATTACHMENTS,
} from '../utilityFunction/constants';

const AuthWithPublicAccess = withPublicAccess(Auth);
const HomeWithPrivateAccess = withPrivateAccess(Home);
const ProfileWithPrivateAccess = withPrivateAccess(ProfileView);
const HomeWithPublicAccess = withPublicAccess(Home);

const config = [
  {
    pagePath: ROOT_PATH,
    element: <HomeWithPrivateAccess headerLabel={DASHBOARD} />,
    views: [
      {
        viewPath: '',
        element: <DashboardView />,
      },
    ],
  },
  {
    pagePath: PROFILE_PATH,
    element: <ProfileWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: MANAGE_SCHOOLS_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_SCHOOLS} />,
    views: [
      {
        viewPath: '',
        element: <ManageSchoolListing />,
      },
    ],
  },
  {
    pagePath: ADD_SCHOOL_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_SCHOOLS} />,
    views: [
      {
        viewPath: '',
        element: <AddSchool />,
      },
    ],
  },
  {
    pagePath: ADD_STUDENT_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STUDENTS} />,
    views: [
      {
        viewPath: '',
        element: <AddStudentForm />,
      },
    ],
  },
  {
    pagePath: UPDATE_STUDENT_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STUDENTS} />,
    views: [
      {
        viewPath: '',
        element: <AddStudentForm />,
      },
    ],
  },
  {
    pagePath: UPDATE_STAFF_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STAFF} />,
    views: [
      {
        viewPath: '',
        element: <AddStaffForm />,
      },
    ],
  },
  {
    pagePath: UPDATE_SCHOOL_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_SCHOOLS} />,
    views: [
      {
        viewPath: '',
        element: <AddSchool />,
      },
    ],
  },
  {
    pagePath: MANAGE_STUDENTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STUDENTS} />,
    views: [
      {
        viewPath: '',
        element: <StudentListing />,
      },
    ],
  },
  {
    pagePath: SCHOOL_ADMINS_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: SCHOOL_STAFF_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: STUDENTS_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: NOTIFICATIONS_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: ISLANDS_CONTENT_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: BACKUP_UPDATES_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: SYSTEM_SETTING_PATH,
    element: <HomeWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    pagePath: LOGIN_PATH,
    element: <AuthWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <LoginForm />,
      },
    ],
  },
  {
    pagePath: FORGOT_PASSWORD_PATH,
    element: <AuthWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ForgotPassword />,
      },
    ],
  },
  {
    pagePath: RESET_PASSWORD_PATH,
    element: <AuthWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <ResetPassword />,
      },
    ],
  },
  {
    pagePath: TEMPORARY_LOGIN_PATH,
    element: <AuthWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <TemporaryLogin />,
      },
    ],
  },

  {
    pagePath: MANAGE_SCHOOL_DISTRICTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={SYSTEM_SETTINGS} />,
    views: [
      {
        viewPath: '',
        element: <ManageSchoolDistrictsListing />,
      },
    ],
  },
  {
    pagePath: MANAGE_STAFF_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STAFF} />,
    views: [
      {
        viewPath: '',
        element: <ManageStaffListing />,
      },
    ],
  },
  {
    pagePath: ADD_STAFF_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_STAFF} />,
    views: [
      {
        viewPath: '',
        element: <AddStaffForm />,
      },
    ],
  },
  {
    pagePath: MANAGE_CONTENTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <IslandsListing />,
      },
    ],
  },
  {
    pagePath: MANAGE_SECTION_PATH,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <SectionListing />,
      },
    ],
  },
  {
    pagePath: MANAGE_LESSONS_PATH,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <ManageLessonListing />,
      },
    ],
  },
  {
    pagePath: LESSON_STYLE_PATH,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <EditLessonStyle />,
      },
    ],
  },
  {
    pagePath: ADD_LESSON_CONTENT_PATH,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <AddLessonContent />,
      },
    ],
  },
  {
    pagePath: REPORTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={REPORTS} />,
    views: [
      {
        viewPath: '',
        element: <StudentProgressReportListing />,
      },
    ],
  },
  {
    pagePath: PILOT_REPORTING_PATH,
    element: <HomeWithPrivateAccess headerLabel={PILOT_REPORTING} />,
    views: [
      {
        viewPath: '',
        element: <PilotReportingView />,
      },
    ],
  },
  {
    pagePath: STAFF_STUDENT_ATTACHMENTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={STAFF_STUDENT_ATTACHMENTS} />,
    views: [
      {
        viewPath: '',
        element: <StaffStudentAttachments />,
      },
    ],
  },
  {
    pagePath: STUDENT_WELCOME_PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <StudentWelcomeBody />,
      },
    ],
  },
  {
    pagePath: MANAGE_AVATAR_PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <AvatarList />,
      },
    ],
  },
  {
    pagePath: MANAGE_ISLAND_LIST,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <StudentIslandList />,
      },
    ],
  },
  {
    pagePath: MANAGE_STUDENT_TROPICAL_SECTION__PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <TropicalSection />,
      },
    ],
  },
  {
    pagePath: MANAGE_STUDENT_URBAN_SECTION__PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <UrbanSection />,
      },
    ],
  },
  {
    pagePath: MANAGE_STUDENT_RURAL_SECTION__PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <RuralSection />,
      },
    ],
  },
  {
    pagePath: MANAGE_STUDENT_FANTASY_SECTION__PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <FantasySection />,
      },
    ],
  },

  {
    pagePath: MANAGE_STUDENT_LESSON__PATH,
    element: <HomeWithPrivateAccess />,
    views: [
      {
        viewPath: '',
        element: <StudentLesson />,
      },
    ],
  },
  {
    pagePath: VERIFY_EMAIL,
    element: <AuthWithPublicAccess />,
    views: [
      {
        viewPath: '',
        element: <VerifyEmail />,
      },
    ],
  },
  {
    pagePath: SHOW_HELP,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <Help />,
      },
    ],
  },
  {
    pagePath: VIEW_PROFILE,
    element: <HomeWithPrivateAccess headerLabel={MY_PROFILE} />,
    views: [
      {
        viewPath: '',
        element: <UserProfile />,
      },
    ],
  },
  {
    pagePath: VIEW_LESSON_CONTENTS,
    element: <HomeWithPrivateAccess headerLabel={ISLANDS_CONTENT} />,
    views: [
      {
        viewPath: '',
        element: <StudentViewLessonContent />,
      },
    ],
  },
  {
    pagePath: MANAGE_QUESTIONS_PATH,
    element: <HomeWithPrivateAccess headerLabel={MANAGE_QUESTIONS} />,
    views: [
      {
        viewPath: '',
        element: <ManageQuestionListView />,
      },
    ],
  },
  {
    pagePath: STUDENT_DETAILS_PATH,
    element: <HomeWithPrivateAccess headerLabel={DASHBOARD} />,
    views: [
      {
        viewPath: '',
        element: <StudentDetailsDashboardView />,
      },
    ],
  },
  {
    pagePath: MANAGE_ANNOUNCEMENTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={ANNOUNCEMENTS} />,
    views: [
      {
        viewPath: '',
        element: <Announcements />,
      },
    ],
  },
  {
    pagePath: MANAGE_SUPER_ADMIN,
    element: <HomeWithPrivateAccess headerLabel={SUPER_ADMIN_MGMT} />,
    views: [
      {
        viewPath: '',
        element: <SuperAdminListing />,
      },
    ],
  },
  {
    pagePath: ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH,
    element: <HomeWithPrivateAccess headerLabel={NOTIFY_ANNOUNCEMENTS} />,
    views: [
      {
        viewPath: '',
        element: <AllNotifcationsAndAnnouncementsListView />,
      },
    ],
  },
];

export default config;
