import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import {View, Text} from '@unthinkable/react-core-components';
import {useDispatch, useSelector} from 'react-redux';
import useUserRole from '../../hooks/useUserRole';
import {
  checkIsSuperAdmin,
  isStaffOrSchoolAdminRole,
} from '../../utilityFunction/helper';
import {downloadPilotReport} from '../../store/actions/pilotReportActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

function PilotReportingView() {
  const intl = useIntl();
  const userRole = useUserRole();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const isUserStaffOrSchoolAdminRole = isStaffOrSchoolAdminRole(userRole);
  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  const handleDownloadPilotReport = async () => {
    const res = await dispatch(downloadPilotReport({intl, loaderDispatch}));
    const downloadLink = document.createElement('a');
    downloadLink.href = res.payload.download_url;
    downloadLink.download = 'student-progress-report.xlsx';
    downloadLink.click();
  };

  return (
    <>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.pilot_report'})}
        </div>
        <div className="add-import-btn">
          <div className="import-btn">
            <button onClick={handleDownloadPilotReport}>
              {intl.formatMessage({id: 'label.download_pilot_report'})}
            </button>
          </div>
        </div>
      </div>

      <div className="px-4">
        <p>
          This report provides detailed insights into the pilot program at your
          selected school. The report includes:
        </p>
        <ul>
          <li>The total number of student users in the piloting school.</li>
          <li>
            The total number of students who completed the program at the
            school.
          </li>
          <li>
            The total number of module page visits during the pilot program.
          </li>
          <li>
            The total number of resources downloaded from the platform by
            students at the school during the pilot program.
          </li>
          <li>
            The number of times students revisited a module during the pilot
            program.
          </li>
        </ul>
      </div>
    </>
  );
}

export default PilotReportingView;
