import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import {v4 as uuidv4} from 'uuid';
import NoContentView from './NoContentView/NoContentView';
import AddContentButtons from './AddContentButtons/AddContentButtons';
import CardTextView from '../../../../components/CardTextView/CardTextView';
import AttachFileCard from '../../../../components/AttachFileCard/AttachFileCard';
import TextEditor from '../../../../components/TextEditor/TextEditor';
import AttachImageVideoCard from '../../../../components/AttachImageVideoCard/AttachImageVideoCard';
import AddArticleCard from '../../../../components/AddArticleCard/AddArticleCard';
import ArticleCardView from '../../../../components/ArticleCardView/ArticleCardView';
import {validationSchema} from '../../../../validationSchema/addLessonContentSchema';
import {
  addLessonContentData,
  getLessonContentData,
  updateLessonContentData,
} from '../../../../store/actions/lessonActions';
import {handleUploadFile} from '../../../../store/actions/fileActions';
import {
  getEditorDescription,
  getLessonContentPayload,
  getLocalizedMessage,
  prefillLessonContentData,
  getPreviewFormData,
} from '../../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import {showToast} from '../../../../components/Toast/Toast';
import {setLoading} from '../../../../globalContext/globalLoader/globalLoaderAction';
import {LESSON_CONTENT_OBJECT_TYPE} from '../../../../utilityFunction/constants';
import CustomModalStudent from '../../../../components/CustomModalStudent/CustomModalStudent';
import StudentViewLessonContent from '../../../Student/StudentViewLessonContent/StudentViewLessonContent';
import LessonContentHeader from '../../../Student/StudentViewLessonContent/LessonContentHeader';
import useUserRole from '../../../../hooks/useUserRole';
import UserRoles from '../../../../constants/userRoles';
import './AddLessonContentStyle.scss';

export default function AddLessonContent() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const {
    lessonData,
    sectionId,
    sectionName,
    isEditContent,
    lessonId,
    lessonBasicDetails,
  } = state;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {lessonContentData} = useSelector(state => state?.lesson);
  const [showAddArticleModal, setShowAddArticleModal] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [showLessonContentModal, setShowLessonContentModal] = useState(false);
  const [resultId, setResultId] = useState(null);
  const userRole = useUserRole();
  const voiceOptions = [
    {
      id: 'Shimmer EN',
      name: 'Shimmer',
    },
    {
      id: 'Nancy',
      name: 'Nancy',
    },
    {
      id: 'Amy plus',
      name: 'Amy',
    },
  ];

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: {
      lesson_contents: [],
    },
    onSubmit: values => {
      const statusType = values.submitType;
      const lessonContentPayload = getLessonContentPayload(
        values?.lesson_contents,
      );
      if (isEditContent && sectionId) {
        if (values?.lesson_contents?.length === 0) {
          showToast(
            getLocalizedMessage(intl, 'info.noLessonContent'),
            'warning',
          );
          return;
        }
        const payload = {
          status: statusType,
          lesson_contents: lessonContentPayload,
        };
        dispatch(
          updateLessonContentData({
            payload,
            lessonId,
            intl,
            navigate,
            sectionName,
            sectionId,
            loaderDispatch,
            dispatch,
          }),
        );
      } else {
        if (values?.lesson_contents?.length === 0) {
          showToast(
            getLocalizedMessage(intl, 'info.noLessonContent'),
            'warning',
          );
          return;
        }
        if (!sectionId) return;
        const payload = {
          ...lessonData,
          status: statusType,
          section_id: sectionId,
          lesson_contents: lessonContentPayload,
        };
        dispatch(
          addLessonContentData({
            payload,
            intl,
            navigate,
            sectionName,
            sectionId,
            loaderDispatch,
            dispatch,
          }),
        );
      }
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    if (isEditContent && lessonBasicDetails) fetchLessonContent();
  }, [isEditContent, lessonBasicDetails]);

  useEffect(() => {
    if (isEditContent && lessonContentData) {
      const formData = lessonContentData?.lesson_content;
      if (formData) {
        const sortedData = [...formData]?.sort((a, b) => {
          return a.content_priority - b.content_priority;
        });
        const prefillData = prefillLessonContentData(sortedData);
        setInitialData(prefillData);
        setValues({lesson_contents: prefillData});
      }
    }
  }, [isEditContent, lessonContentData, setValues]);

  const fetchLessonContent = () => {
    dispatch(
      getLessonContentData({
        loaderDispatch,
        lessonId,
        status: lessonBasicDetails?.status,
      }),
    );
  };

  const onAddTextType = type => {
    const newContentText = {
      contentId: uuidv4(),
      type,
      description: '',
    };
    const newContents = [...values.lesson_contents, newContentText];
    setFieldValue('lesson_contents', newContents);
  };

  const onAddArticle = formValues => {
    const newArticleContent = {
      contentId: uuidv4(),
      type: 'article',
      article_title: formValues?.article_title,
      article_link: formValues?.article_link,
      media_id: formValues?.media_id,
      article_preview_url: formValues?.preview_url,
    };
    setFieldValue('lesson_contents', [
      ...values.lesson_contents,
      newArticleContent,
    ]);
    setShowAddArticleModal(false);
  };

  const onAddContentType = type => {
    if (type === 'article') {
      setShowAddArticleModal(true);
    } else if (type === 'document') {
      const newContent = {
        contentId: uuidv4(),
        media_id: '',
        type,
      };
      setFieldValue('lesson_contents', [...values.lesson_contents, newContent]);
    } else if (type === 'text') {
      const newContent = {
        contentId: uuidv4(),
        type,
        editorDescription: getEditorDescription(),
      };
      setFieldValue('lesson_contents', [...values.lesson_contents, newContent]);
    } else {
      const newContent = {
        contentId: uuidv4(),
        type,
        media_id: '',
      };
      setFieldValue('lesson_contents', [...values.lesson_contents, newContent]);
    }
  };

  const onRemoveItem = (index, name) => {
    const newContents = [...values.lesson_contents];
    newContents.splice(index, 1);
    setFieldValue('lesson_contents', newContents);
    setFieldTouched(name, false);
  };

  const handleFileUpload = async (file, index) => {
    dispatch(handleUploadFile({file, intl, loaderDispatch}))
      .unwrap()
      .then(response => {
        setFieldValue(`lesson_contents[${index}].media_id`, response?.media_id);
        setFieldValue(
          `lesson_contents[${index}].file_preview_url`,
          response?.media_path,
        );
        setFieldValue(`lesson_contents[${index}].file_preview_name`, file.name);
        setFieldTouched(`lesson_contents[${index}].media_id`, true);
      })
      .catch(error => {
        console.error('Upload failed', error);
      });
  };

  const hasNewContent = () => {
    const formHasChanged =
      JSON.stringify(initialData) !== JSON.stringify(values.lesson_contents);
    return formHasChanged;
  };

  const handleSaveDraft = () => {
    if (isEditContent && !hasNewContent()) {
      showToast(
        getLocalizedMessage(intl, 'info.noNewLessonContent'),
        'warning',
      );
      return;
    }
    setFieldValue('submitType', 'draft', false);
    handleSubmit();
  };

  const handlePublishContent = () => {
    setFieldValue('submitType', 'published', false);
    handleSubmit();
  };

  const getLessonHeaderDetails = () => {
    if (isEditContent) {
      return {
        name: lessonBasicDetails?.name,
        minimum_read_time: lessonBasicDetails?.minimum_read_time,
      };
    } else {
      return {
        name: lessonData?.name,
        minimum_read_time: lessonData?.minimum_read_time,
      };
    }
  };

  const handleLessonContentPreview = () => {
    setShowLessonContentModal(true);
  };

  const closePreviewModalHandler = () => {
    setShowLessonContentModal(false);
  };

  const checkIsStudentRole = () => {
    if (userRole === UserRoles.STUDENT) return true;
    else return false;
  };

  const checkIsPreviewAvailable = () => {
    const previewRoles = [UserRoles.SUPER_ADMIN];
    if (previewRoles.includes(userRole)) return true;
    else return false;
  };

  // Drag and Drop
  const [activeCard, setActiveCard] = useState(null);
  const handleDragStart = index => {
    return () => {
      setActiveCard(index);
    };
  };
  const handleDragEnd = () => {
    setActiveCard(null);
  };
  const handleOnDrop = position => {
    if (activeCard === null || activeCard === undefined) return;

    const newContents = [...values.lesson_contents];
    const [removed] = newContents.splice(activeCard, 1);
    newContents.splice(position, 0, removed);
    setFieldValue('lesson_contents', newContents);
  };

  const DropZone = ({onDrop}) => {
    const [showDrop, setShowDrop] = useState(false);
    const handleDragEnter = () => {
      setShowDrop(true);
    };
    const handleDragLeave = () => {
      setShowDrop(false);
    };
    return (
      <section
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={() => {
          onDrop();
          setShowDrop(false);
        }}
        onDragOver={e => e.preventDefault()}
        className={showDrop ? 'drop-area' : 'hide-drop-area'}>
        Drop Here
      </section>
    );
  };
  // End Drag and Drop

  // Text to Speech
  // Load environment variables
  const REACT_APP_SPEECHGEN_API_TOKEN =
    process.env.REACT_APP_SPEECHGEN_API_TOKEN;
  const REACT_APP_SPEECHGEN_EMAIL = process.env.REACT_APP_SPEECHGEN_EMAIL;
  const handleGenerateTextToSpeech = async () => {
    const myFormModifiedData = getPreviewFormData(values?.lesson_contents);
    const textResult = myFormModifiedData.map(item => {
      let text = '';
      if (item.type === 'document') {
        return ''; // Return an empty string to exclude 'document' type
      }
      try {
        const descriptionObj = JSON.parse(item.description);
        text = descriptionObj.blocks.map(block => block.text).join(' ');
      } catch (e) {
        text = item.description;
      }

      // Handle all item types except 'text'
      if (item.type !== 'text') {
        text = `${item.type.toUpperCase()}: ${text}`;
      }

      return text;
    });

    const text = textResult.join(' ').replace(/\s+/g, ' ').trim();

    const generateSpeech = async voice => {
      const data = {
        token: REACT_APP_SPEECHGEN_API_TOKEN,
        email: REACT_APP_SPEECHGEN_EMAIL,
        voice: voice, // dynamic voice assignment
        text,
        format: 'mp3',
        speed: 1,
        pitch: 0,
        emotion: 'good',
      };

      const response = await fetch(
        'https://speechgen.io/index.php?r=api/longtext',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(data),
        },
      );

      return response.json();
    };

    try {
      loaderDispatch(setLoading(true));

      for (const voiceOption of voiceOptions) {
        const result = await generateSpeech(voiceOption.id);

        if (result.status === 1) {
          setResultId(result.id);

          // Wait for the result to be ready before moving to the next voice
          let isReady = false;
          while (!isReady) {
            const checkData = {
              token: REACT_APP_SPEECHGEN_API_TOKEN,
              email: REACT_APP_SPEECHGEN_EMAIL,
              id: result.id,
            };

            const checkResponse = await fetch(
              'https://speechgen.io/index.php?r=api/result',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(checkData),
              },
            );

            const checkResult = await checkResponse.json();

            if (checkResult.status === '1') {
              isReady = true; // Move to the next voice
              showToast(
                `Speech Successfully Generated for ${voiceOption.name}.`,
                'success',
              );
            } else if (checkResult.status === '0') {
              console.log(
                `Voice generation in process for ${voiceOption.name}...`,
              );
              await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before checking again
            } else {
              console.error(checkResult.error);
              loaderDispatch(setLoading(false));
              showToast('Error in checking dubbing status', 'error');
              return;
            }
          }
        } else {
          console.error(result.error);
        }
      }

      loaderDispatch(setLoading(false));
    } catch (error) {
      console.error('Error sending text to API:', error);
      loaderDispatch(setLoading(false));
      showToast('There was an error in generating the speech.', 'error');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (resultId) {
        const checkStatus = async () => {
          const data = {
            token: REACT_APP_SPEECHGEN_API_TOKEN,
            email: REACT_APP_SPEECHGEN_EMAIL,
            id: resultId,
          };

          try {
            loaderDispatch(setLoading(true));

            const response = await fetch(
              'https://speechgen.io/index.php?r=api/result',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(data),
              },
            );

            const result = await response.json();

            if (result.status === '1') {
              setResultId(null); // Clear resultId once audio is ready
              loaderDispatch(setLoading(false));
            } else if (result.status === '0') {
              console.log('Voice generation in process...');
            } else {
              console.error(result.error);
              loaderDispatch(setLoading(false));
            }
          } catch (error) {
            console.error('Error checking dubbing status:', error);
            showToast('Error checking dubbing status', 'error');
            loaderDispatch(setLoading(false));
          }
        };

        checkStatus();
      }
    }, 100);

    return () => clearInterval(interval);
  }, [resultId]);

  return (
    <div className="add-content-container">
      <div className="first-column">
        {values?.lesson_contents?.length === 0 ? (
          <div className="centered-container">
            <NoContentView />
          </div>
        ) : (
          <>
            <DropZone onDrop={() => handleOnDrop(0)} />
            {values?.lesson_contents?.map((contentItem, index) => (
              <div
                key={contentItem?.contentId}
                draggable
                onDragStart={handleDragStart(index)}
                onDragEnd={handleDragEnd}
                className="card-view">
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.ALERT && (
                  <CardTextView
                    headerText={getLocalizedMessage(
                      intl,
                      'label.content.alert',
                    )}
                    name={`lesson_contents[${index}].description`}
                    textContent={contentItem?.description}
                    handleTextChange={value =>
                      setFieldValue(
                        `lesson_contents[${index}].description`,
                        value,
                      )
                    }
                    handleDelete={() =>
                      onRemoveItem(
                        index,
                        `lesson_contents[${index}].description`,
                      )
                    }
                    onBlur={() =>
                      setFieldTouched(
                        `lesson_contents[${index}].description`,
                        true,
                      )
                    }
                    error={
                      touched?.lesson_contents?.[index]?.description &&
                      errors?.lesson_contents?.[index]?.description
                    }
                  />
                )}
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.NOTE && (
                  <CardTextView
                    headerText={getLocalizedMessage(intl, 'label.content.note')}
                    name={`lesson_contents[${index}].description`}
                    textContent={contentItem?.description}
                    handleTextChange={value =>
                      setFieldValue(
                        `lesson_contents[${index}].description`,
                        value,
                      )
                    }
                    handleDelete={() =>
                      onRemoveItem(
                        index,
                        `lesson_contents[${index}].description`,
                      )
                    }
                    onBlur={() =>
                      setFieldTouched(
                        `lesson_contents[${index}].description`,
                        true,
                      )
                    }
                    error={
                      touched?.lesson_contents?.[index]?.description &&
                      errors?.lesson_contents?.[index]?.description
                    }
                  />
                )}
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.REMEMBER && (
                  <CardTextView
                    headerText={getLocalizedMessage(
                      intl,
                      'label.content.remember',
                    )}
                    name={`lesson_contents[${index}].description`}
                    textContent={contentItem?.description}
                    handleTextChange={value =>
                      setFieldValue(
                        `lesson_contents[${index}].description`,
                        value,
                      )
                    }
                    handleDelete={() =>
                      onRemoveItem(
                        index,
                        `lesson_contents[${index}].description`,
                      )
                    }
                    onBlur={() =>
                      setFieldTouched(
                        `lesson_contents[${index}].description`,
                        true,
                      )
                    }
                    error={
                      touched?.lesson_contents?.[index]?.description &&
                      errors?.lesson_contents?.[index]?.description
                    }
                  />
                )}
                {contentItem.type ===
                  LESSON_CONTENT_OBJECT_TYPE.BEST_ADVICE && (
                  <CardTextView
                    headerText={getLocalizedMessage(
                      intl,
                      'label.content.bestAdvice',
                    )}
                    name={`lesson_contents[${index}].description`}
                    textContent={contentItem?.description}
                    handleTextChange={value =>
                      setFieldValue(
                        `lesson_contents[${index}].description`,
                        value,
                      )
                    }
                    handleDelete={() =>
                      onRemoveItem(
                        index,
                        `lesson_contents[${index}].description`,
                      )
                    }
                    onBlur={() =>
                      setFieldTouched(
                        `lesson_contents[${index}].description`,
                        true,
                      )
                    }
                    error={
                      touched?.lesson_contents?.[index]?.description &&
                      errors?.lesson_contents?.[index]?.description
                    }
                  />
                )}
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.ARTICLE && (
                  <ArticleCardView
                    articleTitle={contentItem?.article_title}
                    articleLink={contentItem?.article_link}
                    articlePreviewUrl={contentItem?.article_preview_url}
                    handleArticleDelete={() => onRemoveItem(index)}
                  />
                )}
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.DOCUMENT && (
                  <AttachFileCard
                    handleAttachmentDelete={() =>
                      onRemoveItem(index, `lesson_contents[${index}].media_id`)
                    }
                    onFileUpload={file => handleFileUpload(file, index)}
                    filePreviewName={contentItem?.file_preview_name}
                    name={`lesson_contents[${index}].media_id`}
                    error={
                      touched?.lesson_contents?.[index]?.media_id &&
                      errors?.lesson_contents?.[index]?.media_id
                    }
                  />
                )}
                {contentItem.type === LESSON_CONTENT_OBJECT_TYPE.TEXT && (
                  <TextEditor
                    initialEditorState={contentItem?.editorDescription}
                    handleEditorDelete={() => onRemoveItem(index)}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    name={`lesson_contents[${index}].editorDescription`}
                    error={
                      touched?.lesson_contents?.[index]?.editorDescription &&
                      errors?.lesson_contents?.[index]?.editorDescription
                    }
                  />
                )}
                {contentItem.type ===
                  LESSON_CONTENT_OBJECT_TYPE.IMAGE_VIDEO && (
                  <AttachImageVideoCard
                    handleImageVideoDelete={() =>
                      onRemoveItem(index, `lesson_contents[${index}].media_id`)
                    }
                    initialFile={contentItem?.mediaInfo}
                    typeFile={contentItem?.type_file}
                    error={
                      touched?.lesson_contents?.[index]?.media_id &&
                      errors?.lesson_contents?.[index]?.media_id
                    }
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    mediaNameField={`lesson_contents[${index}].media_id`}
                    mediaPreviewUrlField={`lesson_contents[${index}].file_preview_url`}
                    filePreviewNameField={`lesson_contents[${index}].file_preview_name`}
                    fileTypeNameField={`lesson_contents[${index}].type_file`}
                  />
                )}
                <DropZone onDrop={() => handleOnDrop(index + 1)} />
              </div>
            ))}
          </>
        )}
      </div>

      <div className="second-column">
        <AddContentButtons
          handleAddTextType={type => onAddTextType(type)}
          handleAddContentType={type => onAddContentType(type)}
          saveDraftHandler={handleSaveDraft}
          publishContentHandler={handlePublishContent}
          seePreviewHandler={handleLessonContentPreview}
          generateTextToSpeechHandler={handleGenerateTextToSpeech}
        />
      </div>

      {showAddArticleModal && (
        <AddArticleCard
          open={showAddArticleModal}
          setOpen={setShowAddArticleModal}
          handleAddNewArticle={onAddArticle}
        />
      )}

      {showLessonContentModal && (
        <CustomModalStudent
          open={showLessonContentModal}
          modalWidth={'90%'}
          showHeader
          dialogHeader={
            <LessonContentHeader
              myLessonInformation={getLessonHeaderDetails()}
              isStudentRole={checkIsStudentRole()}
              onBack={closePreviewModalHandler}
            />
          }>
          <StudentViewLessonContent
            isPreviewAvailable={checkIsPreviewAvailable()}
            formValues={values?.lesson_contents}
          />
        </CustomModalStudent>
      )}
    </div>
  );
}
